(function () {
    'use strict';
    // DO NOT EDIT THIS FILE, EDIT THE GULP TASK NGCONSTANT SETTINGS INSTEAD WHICH GENERATES THIS FILE
    angular
        .module('emsApp') //
        .constant('_', window._) // lodash support
        .constant('jQuery', $) // jQuery support
        .constant('VERSION', "Built at 09 Oct 2023 16:13:14") //
        .constant('DEBUG_INFO_ENABLED', false) //
        .constant('BUILD_TIMESTAMP', 1696860799385) //
;
})();
